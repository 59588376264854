<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card col-lg-12 mt-2">
      <div class="card-header flex justify-content-between">
        <div class="card-title">{{ $route.name }} </div>
          <div class="flex flex-row mb-2 pr-2 float-right">
            <el-input clearable @clear="searchHandler" style="width: 200px" size="small" type="text" v-model="search_category.search_value" placeholder="Input search keyword" class="mr-2"/>
            <el-select size="small" v-model="search_category.search" class="mr-2" style="width: 150px">
              <el-option v-for="(item, index) in search_options" :key="index" :value="item.value" :label="item.text"/>
              </el-select>
            <el-button type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
          </div>
      </div>
      <!-- NEXT DEVELOP -->
      <!-- <div class="card-body" v-if="showSearch">
        <b-modal size="sm" id="category"  title="Create Category" hide-footer>
          <b-form @submit.prevent="doCreateCategory">
            <b-form-group label="Name">
              <el-input placeholder="Input Name" v-model="category.name"/>
            </b-form-group>
            <b-form-group label="User ID">
              <el-input :disabled="true" v-model="category.user_id"/>
            </b-form-group>
            <div class="d-flex flex-row float-right">
              <el-button @click="doCreateCategory" class="mr-2 ml-2" size="small" type="primary">Submit Category</el-button>
            </div>
          </b-form>
        </b-modal>
        <b-modal size="sm" id="category_edit"  title="Edit Category" hide-footer>
          <b-form @submit.prevent="doUpdateData">
            <b-form-group label="Name">
              <el-input placeholder="Input Name" v-model="category.name"/>
            </b-form-group>
            <b-form-group label="User ID">
              <el-input :disabled="true" v-model="category.user_id"/>
            </b-form-group>
            <div class="d-flex flex-row float-right">
              <el-button @click="doCreateCategory" class="mr-2 ml-2" size="small" type="primary">Submit Category</el-button>
            </div>
          </b-form>
        </b-modal>
      </div> -->
      <div class="table-container">
        <div class="table-responsive">
          <table class="table card-table text-nowrap table-vcenter">
            <thead>
              <tr>
                <th width="25%">ID</th>
                <th width="25%">Name</th>
                <th width="25%">Created At</th>
                <th width="25%">Updated At</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in list" :key="i">
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.created_at_str }}</td>
                <td>{{ item.updated_at_str }}</td>
                <td class="text-nowrap text-muted child1-sticky"></td>
              </tr>
            </tbody>
          </table>
          <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
        </div>
      </div>
      <pagination :page="page" :per_page="per_page" :list="list" :total_rows="total_rows"
        @pageChange="pageChangeHandler" />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { upperFirst } from 'lodash';
import emptyStateImage from '@/assets/images/empty-state.png';
import Pagination from '../components/Pagination.vue';
import { EDIT_CATEGORY, CREATE_CATEGORY, GET_CATEGORIES } from '../../store/modules/app_user';
import popupErrorMessages from '@/library/popup-error-messages';
export default {
  components: { Pagination },
  name: 'Referral Categories',
  metaInfo: {
    title: 'Referral Categories',
  },
  data() {
    return {
      category: {
        name: '',
        user_id: null,
      },
      user_id: null,
      search_options: [
        { value: 'name', text: 'Name' },
      ],
      search_category: {
        search: 'name',
        search_value: '',
      },
      emptyStateImage,
      per_page: 20,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      loader: null,
      datePickerOptions: {
        disabledDate: (date) => moment(date).isBefore(moment(), 'days'),
      },
    };
  },
  async created() {
    // CATEGORY
    this.category.user_id = localStorage.getItem('user_id');
    console.log('User ID bener dari category:', this.category.user_id);
  },
  async mounted() {
    const loader = this.$loading.show();
    // this.page = 1;
    await this.getList();
    loader.hide();
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
  },
  methods: {
    async getList() {
      await this.$store.dispatch(GET_CATEGORIES, {
        search: this.search_category.search,
        search_value: this.search_category.search_value
      }).catch(() => {});
      const { rows, count } = this.$store.getters.get_categories;
      this.total_rows = count || 0;
      this.list = rows;
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.search_category.search = this.search_category.search;
      this.search_category.search_value = this.search_category.search_value; 
      this.getList();
    },
    // CREATE CATEGORY
    async doCreateCategory() {
      console.log(this.category);
      await this.$store.dispatch(CREATE_CATEGORY, this.category)
        .then(() => {
          this.$message({
            title: 'Success',
            type: 'success',
            message: 'Category created',
          });
        }).catch((err) => {
          popupErrorMessages(err.response.data);
        });
    },
    // UPDATE
    async doUpdateData() {
      this.$confirm('You will update this category. Make sure you have input data correctly. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            await this.$store.dispatch(EDIT_CATEGORY, this.category)
              .then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Category updated',
                });
                this.showModalCreate = false;
                this.getList();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
            return;
          }
          done();
        },
      }).catch(() => {});
    },
  },
};
</script>
<style>
.el-range-separator {
  width: 10% !important;
}

.child1-sticky {
  position: sticky;
  right: 0px;
  width: 40px;
  background: #fff;
}

.child2-sticky {
  position: sticky;
  right: 69px;
  width: 255px;
  background: #fff;
}
/* del */
.table-container {
  width: 100%;
  overflow-x: auto;
}

.table-responsive {
  overflow-x: auto;
}

.buttonload {
  background-color: #006EA5; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px /* Set a font size */
}

.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>